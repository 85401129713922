// src/App.tsx
import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AppNavbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Provider } from "react-redux";
import store from "./store";
import "./App.scss";
import "./i18n";

const App = () => {
  const homeRef = useRef<HTMLDivElement>(null); 
  const aboutRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Provider store={store}>
      <div id="root">
        <AppNavbar
          scrollToHome={scrollToHome}
          scrollToAbout={() => scrollToSection(aboutRef)}
          scrollToServices={() => scrollToSection(servicesRef)}
          scrollToContact={() => scrollToSection(contactRef)}
        />
        <div className="app-container">
          <div ref={homeRef} id="home" className="section">
            <Home scrollToServices={() => scrollToSection(servicesRef)} scrollToContactUs={() => scrollToSection(contactRef)}/>
          </div>
          <div ref={servicesRef} id="services" className="section">
            <Services />
          </div>
          <div ref={aboutRef} id="about" className="section">
            <About />
          </div>
          <div ref={contactRef} id="contact" className="section">
            <Contact />
          </div>
        </div>
        <Footer />
      </div>
    </Provider>
  );
};

export default App;
