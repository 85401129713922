// src/components/Contact.tsx
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import emailjs from "emailjs-com";
import "./Contact.scss";
import { useTranslation } from "react-i18next";

const Contact: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSubmitted(true);

    emailjs
      .send(
        "service_4j5mjhr",
        "template_r10emdl",
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
          user_email: formData.email,
        },
        "OnQrNG00ylkC2XLbl"
      )
      .finally(() => {
        setIsLoading(false);
      });

    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <Container className={`py-5 contact ${className}`}>
      <Row>
        <Col className="text-center">
          <h2 className="contact-title">{t("contact.title")}</h2>
          <p className="contact-description">{t("contact.description")}</p>
          {isSubmitted && !isLoading ? (
            <div className="thank-you-message">
              <h4>{t("contact.thankYouTitle")}</h4>
              <p>{t("contact.thankYouMessage")}</p>
            </div>
          ) : (
            <Form className="contact-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>{t("contact.name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={t("contact.namePlaceholder")}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>{t("contact.email")}</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={t("contact.emailPlaceholder")}
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>{t("contact.message")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder={t("contact.messagePlaceholder")}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("contact.submit")
                )}
              </Button>
            </Form>
          )}
          <div className="contact-info">
            <p className="contact-description">
              {t("contact.contactInfo.email")}
            </p>
            <p className="contact-description">
              {t("contact.contactInfo.address")}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
