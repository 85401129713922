// src/components/Navbar.tsx
import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import "./Navbar.scss";
import { useTranslation } from "react-i18next";
import LogoImage from "../assets/logo.png";
import CzechFlag from "../assets/czech.png";
import EnglishFlag from "../assets/english.png";
import GermanFlag from "../assets/german.png";
interface AppNavbarProps {
  scrollToHome: () => void;
  scrollToAbout: () => void;
  scrollToServices: () => void;
  scrollToContact: () => void;
}

const AppNavbar: React.FC<AppNavbarProps> = ({
  scrollToHome,
  scrollToAbout,
  scrollToServices,
  scrollToContact,
}) => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleNavClick = (scrollFunc: () => void, sectionName: string) => {
    scrollFunc();
    setExpanded(false); 
  };

  return (
    <Navbar
      ref={navbarRef}
      bg="light"
      variant="light"
      expand="lg"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      className="fixed-top custom-navbar"
    >
      <Container>
        <Navbar.Brand
          onClick={() => handleNavClick(scrollToHome, "home")}
          className="navbar-logo"
        >
          <img src={LogoImage} alt="Octopod Logo" className="navbar-logo-img" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => handleNavClick(scrollToHome, "home")}>
              {t("navbar.home")}
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavClick(scrollToServices, "services")}
            >
              {t("navbar.services")}
            </Nav.Link>
            <Nav.Link onClick={() => handleNavClick(scrollToAbout, "about")}>
              {t("navbar.about")}
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavClick(scrollToContact, "contact")}
            >
              {t("navbar.contact")}
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <Button
              variant="light"
              onClick={() => changeLanguage("cs")}
              className={`language-switch ${
                i18n.language === "cs" ? "active" : ""
              }`}
            >
              <img src={CzechFlag} alt="Čeština" className="language-icon" />
            </Button>
            <Button
              variant="light"
              onClick={() => changeLanguage("en")}
              className={`language-switch ${
                i18n.language === "en" ? "active" : ""
              }`}
            >
              <img src={EnglishFlag} alt="English" className="language-icon" />
            </Button>
            <Button
              variant="light"
              onClick={() => changeLanguage("de")}
              className={`language-switch ${
                i18n.language === "de" ? "active" : ""
              }`}
            >
              <img src={GermanFlag} alt="Deutsch" className="language-icon" />
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
