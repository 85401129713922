import React from "react";
import { Container } from "react-bootstrap";
import "./Home.scss";
import LogoImage from "../assets/Octopod.svg";
import { useTranslation } from "react-i18next";

interface HomeProps {
  scrollToServices: () => void;
  scrollToContactUs: () => void;
}

const Home: React.FC<HomeProps> = ({ scrollToServices, scrollToContactUs }) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="p-0 hero-section">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          src={LogoImage}
          alt="Octopod Logo"
          className="octopod-logo-rectangle"
        />
        <p className="hero-text">{t("home.description")}</p>
        <div className="button-group mt-4">
          <button onClick={scrollToServices} className="btn btn-secondary me-3">
            {t("home.learnMore")}
          </button>
          <button
            onClick={scrollToContactUs}
            className="btn btn-primary me-3"
          >
            {t("home.contactUs")}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Home;
