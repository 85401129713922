import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Services.scss";
import { useTranslation } from "react-i18next";

import icon1 from "../assets/1.svg";
import icon2 from "../assets/2.svg";
import icon3 from "../assets/3.svg";
import icon4 from "../assets/4.svg";
import icon5 from "../assets/5.svg";
import icon6 from "../assets/6.svg";
import icon7 from "../assets/7.svg";
import icon8 from "../assets/8.svg";
import icon9 from "../assets/9.svg";

interface Service {
  title: string;
  description: string;
}

const Services: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  const services: Service[] = t("services.cards", {
    returnObjects: true,
  }) as Service[];

  const icons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9];

  return (
    <Container className={`py-5 services ${className}`}>
      <Row>
        <Col>
          <h2 className="services-title">{t("services.title")}</h2>
          <p className="services-description">{t("services.description")}</p>
          <Row className="services-cards">
            {services.map((service, index) => (
              <Col md={4} className="mb-4" key={index}>
                <Card
                  className="service-card h-100"
                  style={{
                    backgroundImage: `url(${icons[index]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{service.title}</Card.Title>
                    <Card.Text>{service.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
